import { useMemo } from 'react';
import React from 'react';
import styles from './Regions.module.scss';
import { getRegionTitles } from '../utils.js';

interface FlatListProps {
  list: any[];
  delimiter: string;
}

const FlatList: React.FC<FlatListProps> = ({ list, delimiter }) =>
  list.reduce((prev, curr) => [...prev, delimiter, curr], []).slice(1);

interface RegionsProps {
  announce: any;
  regionsByCode: any;
  placeholder?: string;
}

const Regions: React.FC<RegionsProps> = ({
  announce,
  regionsByCode,
  placeholder = 'Не определен',
}) => {
  const regionTitles = useMemo(() => {
    return getRegionTitles({ announce, regionsByCode, placeholder });
  }, [announce, placeholder, regionsByCode]);

  return (
    <div className={styles.container}>
      <span>Регион:</span>
      <span className={styles.value}>
        <FlatList list={regionTitles} delimiter=", " />
      </span>
    </div>
  );
};

export default Regions;
