import React from 'react';
import styles from './Duration.module.scss';

interface Props {
  duration: any;
}

const formatInt = (number: number): string => String(number).padStart(2, '0');

const Duration: React.FC<Props> = ({ duration }) => {
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return (
    <span className={styles.duration}>
      <span className={styles.title} data-content="д">
        {formatInt(days)}
      </span>
      <span className={styles.delimiter}>:</span>
      <span className={styles.title} data-content="ч">
        {formatInt(hours)}
      </span>
      <span className={styles.delimiter}>:</span>
      <span className={styles.title} data-content="м">
        {formatInt(minutes)}
      </span>
      <span className={styles.delimiter}>:</span>
      <span className={styles.title} data-content="с">
        {formatInt(seconds)}
      </span>
    </span>
  );
};

export default Duration;
