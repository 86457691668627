import clsx from 'clsx';
import React from 'react';
import { formatCurrency } from '../../../utils/formatCurrency.js';
import styles from './CurrencyValue.module.scss';

interface Props {
  className?: string;
  value: number;
  currencyCode: string;
  strong?: boolean;
  title?: string;
  mute?: string;
}

const CurrencyValue: React.FC<Props> = ({
  value,
  currencyCode = 'RUB',
  className,
  strong = false,
  mute = false,
  title,
}) => (
  <div className={clsx(styles.container, mute && styles.mute, className)}>
    <span className={clsx(styles.value, strong && styles.strong)} title={title}>
      {formatCurrency(value, currencyCode)}
    </span>
  </div>
);

export default React.memo(CurrencyValue);
