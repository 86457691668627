import * as yup from 'yup';
import checkInn from '../../../utils/checkInn.js';

const MAX_FILE_SIZE = 30 * 1024 * 1024; // 30 Mb

export const validationSchema = yup.object({
  inn: yup
    .string()
    .required('Заполните поле')
    .test('inn', 'Указано некорректное значение ИНН', (value) => {
      return checkInn(value);
    }),
  name: yup
    .string()
    .required('Заполните поле')
    .min(2, 'Поле должно содержать не менее 2 символов')
    .max(50, 'Должно быть не более 50 символов.'),
  phone: yup
    .string()
    .required('Заполните поле')
    .test('phone', 'Неверный формат', (value: string | any): boolean => {
      const regexPhone =
        /^(\+7|7|8)?[\s-]?\(?[489][0-9]{2}\)?[\s-]?[0-9]{3}[\s-]?[0-9]{2}[\s-]?[0-9]{2}$/;
      return regexPhone.test(value);
    }),
  email: yup.string().required('Заполните поле').email('Неверный формат'),
  docs: yup
    .mixed()
    .test('file', 'Необходимо прикрепить файл', (value) => {
      if (value) return value.length > 0;
      return false;
    })
    .test('fileSize', 'Размер файла не должен превышать 30 Мб', (value) => {
      if (value) {
        return value.every((file: File) => file.size <= MAX_FILE_SIZE);
      }
      return false;
    }),
});
