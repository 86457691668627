import { some } from 'lodash';
import { useMemo } from 'react';
import { Purchase } from '../../../types/entities.js';

export const useIsBiddingForUnit = (announce: Purchase) =>
  useMemo(
    () =>
      some(announce.phases, (phase) =>
        some(
          phase.props,
          (prop) => prop.name === 'bid_for_unit' && Number(prop.value),
        ),
      ),
    [announce.phases],
  );
