import type {
  PhaseProp,
  Purchase,
  PurchasePhase,
} from '../../../types/entities.js';
import type { UiTemplateType } from '../../../types/UiTemplateType.js';
import { uiTemplateParse } from '../../../utils/uiTemplateUtils.js';

export const getTemplateConfig = (announce: Purchase): UiTemplateType => {
  const phases = announce?.phases;
  const props: PurchasePhase['props'] = [];
  phases.forEach((phase) => {
    if (phase.props?.length) {
      props.push(...phase.props);
    }
  });
  const uiCustomBlocksProp: PhaseProp | undefined = props.find(
    (prop) => prop.name === 'ui_custom_blocks',
  );
  return uiTemplateParse(uiCustomBlocksProp);
};
