import concat from 'lodash/concat.js';
import map from 'lodash/map.js';
import reduce from 'lodash/reduce.js';
import uniq from 'lodash/uniq.js';
import { LotTariffOption } from '../types/entities.js';

const unique = (values: any[]) => uniq(values).filter(Boolean);

export function getAnnounceFreeParticipationOrgIds(
  announce: any,
  mskMoment: any,
) {
  const nowMoment = mskMoment('now');

  /*
   * Пропускаем извещения без организатора (например отмененные)
   */
  if (announce.organization === null) {
    return [];
  }

  /*
   * Условия при которых закупка бесплатна для всех
   */
  if (
    /*
     * Закупка с флагом isFree (код 3)
     */
    announce.isFree ||
    /*
     * Закупка по способу с флагом isFree (код 7)
     */
    announce?.procedure?.isFree ||
    /*
     * Закупка по регламенту со снятым флагом isTpFunds (код 2)
     */
    announce?.procedure?.reglament?.isTpFunds === false ||
    /*
     * При наличии актуальной записи tender_free_customer для организатора (код 8)
     */
    announce?.organization?.freeCustomer?.some(
      (entry: any) => mskMoment(entry.actualTillDate) > nowMoment,
    )
  ) {
    return [0];
  }

  /*
   * Участие в закупке в общем платное, но может быть бесплатным для отдельных участников
   */
  return unique(
    concat(
      /*
       * Записи из tender_free_entry для извещения (код 4)
       */
      map(announce?.freeEntries, 'orgId'),
      /*
       * Записи из tender_free_relationship для организатора извещения (код 10)
       */
      reduce(
        announce?.organization?.freeParticipationParticipants,
        (acc, entry: any) => {
          if (mskMoment(entry.actualTillDate) >= nowMoment) {
            acc.push(entry.participantOrgId);
          }
          return acc;
        },
        [] as any[],
      ),
    ),
  );
}

export default function getComputedDcContractPrice(
  lot: any,
  { hasBiddingForUnit }: any,
) {
  const isCurrencyRub = lot.startPriceCurrencyDigitalCode === '643';

  const computedContractPrice = [
    hasBiddingForUnit && lot.dcMaxContractPrice,
    hasBiddingForUnit && isCurrencyRub && lot.maxContractPrice,
    !hasBiddingForUnit && lot.dcStartPrice,
    !hasBiddingForUnit && isCurrencyRub && lot.startPrice,
  ].find(Boolean);

  return computedContractPrice || 0;
}

export function getLotFreeParticipationOrgIds(lot: any, mskMoment: any) {
  if (!lot.lotTariffOptions) {
    return [];
  }

  const announceFreeParticipationOrgIds = getAnnounceFreeParticipationOrgIds(
    lot?.announce,
    mskMoment,
  );

  /*
   * Условия при которых закупка бесплатна для всех
   */
  if (
    /*
     * Участие в закупке в целом бесплатное
     */
    announceFreeParticipationOrgIds.includes(0) ||
    lot.lotTariffOptions.some(
      (tariffOption: LotTariffOption) =>
        (!tariffOption.isWithSubscriptionOnly &&
          tariffOption.tariffSum === 0) ||
        tariffOption.tariffSumWithSubscription === 0,
    )
  ) {
    return [0];
  }

  return announceFreeParticipationOrgIds;
}
