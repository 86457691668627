import every from 'lodash/every.js';
import find from 'lodash/find.js';
import last from 'lodash/last.js';
import map from 'lodash/map.js';
import maxBy from 'lodash/maxBy.js';
import reject from 'lodash/reject.js';
import React, { useMemo } from 'react';
import { Link } from '@/npm/next.js';
import { Card } from '../../../../base-components/Card.js';
import { LotTariff, Purchase } from '../../../../types/entities.js';
import { getLotFreeParticipationOrgIds } from '../../../../utils/calcIsFreeParticipation.js';
import toMskMoment from '../../../../utils/toMskMoment.js';
import AnnounceNumber from '../../components/AnnounceNumber.js';
import { AnnounceStartPrice } from '../../components/AnnounceStartPrice.js';
import { CommercialOffer } from '../../../Purchase/CommercialOffer/index.js';
import CustomerLogo from '../../components/CustomerLogo.js';
import FreeParticipationBadge from '../../components/FreeParticipationBadge.js';
import MultiCustomersList from '../../components/MultipleCustomersList.js';
import Organizer from '../../components/Organizer.js';
import Phase from '../../components/Phase.js';
import Procedure from '../../components/Procedure.js';
import PublishDate from '../../components/PublishDate.js';
import Regions from '../../components/Regions.js';
import ReglamentBadge from '../../components/ReglamentBadge.js';
import TariffBadge from '../../components/TariffBadge.js';
import Title from '../../components/Title.js';
import { useCustomNmcTitle } from '../../hooks/useCustomNmcTitle.js';
import { useIsBiddingForUnit } from '../../hooks/useIsBiddingForUnit.js';
import styles from './ListItem.module.scss';
import { ListItemProps } from './ListItem.type.js';
import { flatten } from 'lodash';
import SmpBadge from '../../components/SmpBadge.js';
import { useIsSomeLotSmp } from '../../hooks/useIsSomeLotSmp.js';
import { useLayoutContext } from '../../../LayoutContext.js';

export const isEveryoneFreeParticipation = (announce: Purchase): boolean => {
  return (
    !!announce.lots?.length &&
    every(announce.lots, (lot) =>
      getLotFreeParticipationOrgIds({ ...lot, announce }, toMskMoment).includes(
        0,
      ),
    )
  );
};

const ListItem: React.FC<ListItemProps> = ({ announce, regionsByCode }) => {
  const { embedded } = useLayoutContext();
  const tariff = useMemo((): LotTariff | undefined => {
    const tariffs = map(announce.lots, 'tariff');
    return maxBy(tariffs, 'multilotWeight');
  }, [announce.lots]);

  const phases = reject(announce.phases, ['block.type.name', 'dealing']);
  const currentPhase = find(phases, ['id', announce.phaseId]);
  const lastPhase = last(phases);
  const isAnnounceCanceled = announce.status?.name === 'canceled';

  const logo = useMemo(
    () => announce?.organization?.publicProps?.find((p) => p.type === 'logo'),
    [announce],
  );

  const isCommercialOfferVisible = useMemo(() => {
    return announce.phases.some((phase) =>
      phase.props.find(
        (prop) => prop.name === 'as_commercial_proposal' && prop.value,
      ),
    );
  }, [announce.phases]);

  const isSomeLotSmp = useIsSomeLotSmp(announce);
  const isBiddingForUnit = useIsBiddingForUnit(announce);
  const customNmcTitle = useCustomNmcTitle(announce);
  const isPropDisplayNmcFields =
    flatten(phases.map((phase) => phase.props)).find(
      (prop) => prop.name === 'display_nmc_fields',
    )?.value ?? true;

  return (
    <Link
      href={`/purchases/${announce.id}/main`}
      className={styles.link}
      target={embedded ? '_blank' : undefined}
    >
      <Card className={styles.card}>
        <div className={styles.main}>
          <div className={styles.badges}>
            <ReglamentBadge reglament={announce?.procedure?.reglament} />
            <TariffBadge tariff={tariff} />
            <FreeParticipationBadge
              isCurrentOrgFreeParticipation={isEveryoneFreeParticipation(
                announce,
              )}
            />
            <SmpBadge isSomeLotSmp={isSomeLotSmp} />
            <MultiCustomersList
              customers={announce.customers}
              hasMultipleCustomers={announce.customers?.length > 1}
            />
          </div>
          <div className={styles.regions}>
            <Regions announce={announce} regionsByCode={regionsByCode} />
          </div>
          <div className={styles.organizer}>
            <Organizer organizer={announce?.organizer} />
          </div>
          <div className={styles.title}>
            <Title title={announce.title} />
          </div>
          <div className={styles.spacer} />
          <div className={styles.number}>
            <AnnounceNumber number={announce?.number} />
          </div>
          <div className={styles.procedure}>
            <Procedure procedure={announce.procedure} />
          </div>
          <div className={styles.startPriceRow}>
            <div className={styles.startPrice}>
              {isPropDisplayNmcFields && (
                <span>
                  {isBiddingForUnit ? 'МЦД' : customNmcTitle.abbrNmcTitle}:{' '}
                </span>
              )}
              {isPropDisplayNmcFields && (
                <AnnounceStartPrice
                  lots={announce?.lots}
                  isBiddingForUnit={isBiddingForUnit}
                  className={styles.value}
                />
              )}
            </div>
            {isCommercialOfferVisible && (
              <CommercialOffer announceNumber={announce?.number} />
            )}
          </div>
        </div>
        <div className={styles.side}>
          <div className={styles.phase}>
            {announce.phaseId ? (
              <Phase
                phase={currentPhase}
                isAnnounceCanceled={isAnnounceCanceled}
              />
            ) : (
              <Phase
                phase={lastPhase}
                last
                isAnnounceCanceled={isAnnounceCanceled}
              />
            )}
          </div>
          <CustomerLogo logo={logo} />
          <div className={styles.publishDate}>
            <PublishDate publishDate={announce?.datePublication} />
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default ListItem;
