import clsx from 'clsx';
import camelCase from 'lodash/camelCase.js';
import find from 'lodash/find.js';
import React from 'react';
import formatMomentDate from '../../../utils/formatMomentDate.js';
import toMskMoment from '../../../utils/toMskMoment.js';
import styles from './Phase.module.scss';
import PhaseTimer from './PhaseTimer.js';

const getPhaseTitle = (phase: any): string => {
  if (phase === 'Закупка отменена') {
    return phase;
  }
  if (phase === null) {
    return 'Завершено';
  }
  return phase?.title || phase?.block?.title || '—';
};

interface Props {
  phase: any;
  isAnnounceCanceled?: boolean;
  last?: boolean;
}

const PhaseTitle = ({ phase }: { phase: any }) => (
  <span>{getPhaseTitle(phase)}</span>
);

const Phase: React.FC<Props> = ({ phase, last, isAnnounceCanceled }) => {
  const finishTs = toMskMoment(
    find(phase?.props, ['name', 'finish_ts'])?.value,
  );
  const themeClassName =
    styles[(!last && camelCase(phase?.block?.type?.name)) || 'finished'];

  return (
    <div className={styles.phase}>
      <div className={clsx(styles.title, themeClassName)}>
        <PhaseTitle
          phase={isAnnounceCanceled ? 'Закупка отменена' : last ? null : phase}
        />
      </div>
      {Boolean(finishTs) && (
        <div className={clsx(styles.date, themeClassName)}>
          {!last && 'до '}
          {formatMomentDate(finishTs, 'datetime')} (МСК)
        </div>
      )}
      {Boolean(finishTs && !last) && <PhaseTimer finishTs={finishTs} />}
    </div>
  );
};

export default Phase;
