import collectAnnounceRegionCodes from '../../utils/collectAnnounceRegionCodes.js';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty.js';
import { Region } from '../../types/entities.js';

export const setStartOfDay = (value: DateTime | null) => {
  return value?.startOf('day') || null;
};

export const setEndOfDay = (value: DateTime | null) => {
  return value?.endOf('day') || null;
};

export const getRegionTitles = ({
  announce,
  regionsByCode,
  placeholder,
}: {
  announce: any;
  placeholder: string;
  regionsByCode?: Record<string, Region>;
}) => {
  const regionCodes = collectAnnounceRegionCodes(announce);

  if (isEmpty(regionCodes) || !regionsByCode) {
    return [placeholder];
  }

  return regionCodes
    .map((code) => regionsByCode[code])
    .filter(Boolean)
    .map((region) => `${region.offName} ${region.shortName}`);
};
