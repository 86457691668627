import type { UiTemplateType } from '../types/UiTemplateType.js';
import type { CustomNmcObj } from '../types/entities.js';

const getCustomNmcFromUiTemplate = (
  uiTemplate: UiTemplateType | null,
): CustomNmcObj => {
  const lotStartPrice = uiTemplate?.lotStartPrice;
  return {
    abbrNmcTitle: lotStartPrice?.abbr ? lotStartPrice.abbr : 'НМЦ',
    genitiveCaseAbbrTitle: lotStartPrice?.genitiveCaseAbbr
      ? lotStartPrice.genitiveCaseAbbr
      : 'НМЦ',
    fullNmcTitle: lotStartPrice?.title
      ? lotStartPrice.title
      : 'Начальная (максимальная) цена',
  };
};

export default getCustomNmcFromUiTemplate;
