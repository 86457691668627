export const formatFileSize = (size: number): string => {
  if (0 < size && size < 1024) {
    return `${size} байт`;
  }
  if (1024 <= size && size < 1048576) {
    return `${Math.round(size / 1024)
      .toString()
      .replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')} Кб`;
  }
  if (1048576 <= size && size < 1073741824) {
    return `${(size / 1048576).toFixed(2)} Мб`;
  }
  if (size >= 1073741824) {
    return `${(size / 1073741824).toFixed(3)} Гб`;
  }
  return `${size} байт`;
};
