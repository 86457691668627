import React from 'react';
import Badge from './Badge.js';
import styles from './FreeParticipationBadge.module.scss';

type Props = {
  isSomeLotSmp: boolean | number;
};

const SmpBadge: React.FC<Props> = ({ isSomeLotSmp }) =>
  isSomeLotSmp ? <Badge className={styles.badge}>СМП</Badge> : null;

export default SmpBadge;
