import React from 'react';
import styles from './AnnounceNumber.module.scss';

type Props = {
  number: string;
};

const AnnounceNumber: React.FC<Props> = ({ number }) => (
  <div className={styles.container}>
    <span>Код закупки:</span>
    <span className={styles.number}>{number}</span>
  </div>
);

export default AnnounceNumber;
