import _pino from 'pino';

export const pino = _pino as unknown as typeof _pino.default;

export const logger = pino({
  // disable adding time property to each log
  timestamp: false,
  // disable adding pid and hostname properties to each log
  base: {},
});

export default logger;
