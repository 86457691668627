import React from 'react';
import styles from './Procedure.module.scss';

interface Props {
  procedure: {
    title: string;
  };
}

const Procedure: React.FC<Props> = ({ procedure }) => (
  <div className={styles.procedure}>
    <span className={styles.label}>Способ закупки:</span>
    <span className={styles.title}>{procedure?.title || '—'}</span>
  </div>
);

export default Procedure;
