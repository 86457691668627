import clsx from 'clsx';
import React from 'react';
import styles from './Badge.module.scss';

interface BadgeProps {
  className?: string;
  children?: any;
  reglamentName?: 'fz223' | 'eshop' | 'com';
}

const Badge: React.FC<BadgeProps> = ({
  className,
  children,
  reglamentName,
}) => (
  <div
    className={clsx(
      styles.badge,
      className,
      reglamentName && styles[reglamentName],
    )}
  >
    {children}
  </div>
);

export default Badge;
