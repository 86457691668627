import React from 'react';
import styles from './Organizer.module.scss';

interface Props {
  organizer: {
    shortName: string;
  };
}

const Organizer: React.FC<Props> = ({ organizer }) => (
  <div className={styles.container}>
    <span className={styles.label}>Организатор:</span>
    <span className={styles.value}>{organizer?.shortName || '—'}</span>
  </div>
);

export default Organizer;
