import { useMemo } from 'react';
import type { CustomNmcObj, Purchase } from '../../../types/entities.js';
import getCustomNmcFromUiTemplate from '../../../utils/getCustomNmcFromUiTemplate.js';
import { getTemplateConfig } from './getTemplateConfig.js';

export const useCustomNmcTitle = (announce: Purchase): CustomNmcObj =>
  useMemo(() => {
    const templateConfig = getTemplateConfig(announce);
    return getCustomNmcFromUiTemplate(templateConfig);
  }, [announce]);
