import clsx from 'clsx';
import { Link } from '@/npm/next.js';
import React from 'react';
import { Popup } from '@/npm/popup.js';
import { LotTariff } from '../../../types/entities.js';
import { useLayoutContext } from '../../LayoutContext.js';
import Badge from './Badge.js';
import styles from './TariffBadge.module.scss';

interface TariffBadgeProps {
  tariff: LotTariff | undefined;
}

const TariffBadge: React.FC<TariffBadgeProps> = ({ tariff }) => {
  const { embedded } = useLayoutContext();

  if (!tariff) {
    return null;
  }

  return (
    <div suppressHydrationWarning>
      {process.browser && (
        <Popup
          trigger={
            <div>
              <Badge className={clsx(styles.badge)}>
                {`Тариф «${tariff.name}»`}
              </Badge>
            </div>
          }
          on="hover"
          position="bottom left"
          mouseEnterDelay={300}
        >
          <Link
            href={'/tariffs/licenses'}
            className={styles.link}
            target={embedded ? '_blank' : undefined}
          >
            Подробнее о тарифе
          </Link>
        </Popup>
      )}
    </div>
  );
};

export default TariffBadge;
