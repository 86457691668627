import React from 'react';
import { PublicProp } from '../../../types/entities.js';
import { getHref } from '../../../utils/getHref.js';

interface Props {
  logo?: PublicProp;
}

const CustomerLogo: React.FC<Props> = ({ logo }) => {
  const logoImage = logo?.edeFile?.url;

  return logoImage ? (
    <img src={getHref(logoImage)} height={40} width={80} alt="logo" />
  ) : null;
};

export default CustomerLogo;
