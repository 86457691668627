import {
  FieldHelperProps,
  FieldInputProps,
  FieldMetaProps,
  useField,
} from 'formik';
import clsx from 'clsx';
import { FC, useRef, useCallback } from 'react';
import { useUpdateEffect } from 'react-use';
import { ErrorMessage } from './ErrorMessage.js';
import { Button } from './Button.js';
import styles from './FormFileInput.module.scss';
import { TrashIcon } from '../components/Icons/index.js';
import { formatFileSize } from '../utils/formatFileSize.js';

interface Props {
  name: string;
  className: string;
  label: string;
  single?: boolean;
}

export const FormFileInput: FC<Props> = ({
  name,
  label,
  className,
  single,
}) => {
  const filesRef = useRef<HTMLInputElement>(null);
  const [field, meta, helpers]: [
    FieldInputProps<File[]>,
    FieldMetaProps<File[]>,
    FieldHelperProps<File[]>,
  ] = useField(name);

  const handleFiles = useCallback(
    (event: any) => {
      helpers.setTouched(true);
      const isFileUploaded: number = field.value.findIndex(
        (file) => file.name === event.target.files[0].name,
      );
      // Prevent from uploading the same file
      if (isFileUploaded < 0) {
        helpers.setValue([...field.value, event.target.files[0]], true);
      }
    },
    [field.value, helpers],
  );

  const handleDelete = useCallback(
    (fileName: string) => {
      helpers.setValue(field.value.filter((file) => file.name !== fileName));
    },
    [field.value, helpers],
  );

  useUpdateEffect(() => {
    helpers.setValue(field.value);
    // clear input value
    if (filesRef.current && filesRef.current.value) {
      filesRef.current.value = '';
    }
  }, [field.value]);

  const isButtonUploadVisible = single ? field.value.length < 1 : true;

  return (
    <div className={className}>
      <div
        className={clsx(
          styles.field,
          Boolean(meta.touched && meta.error) && styles.error,
        )}
      >
        {label}
        <input type="file" name={name} ref={filesRef} onChange={handleFiles} />
        <ol className={styles.list}>
          {field.value.map(({ name, size }) => {
            return (
              <li key={name}>
                <div>
                  <div>
                    <div>{name}</div>
                    <div>
                      <TrashIcon onClick={() => handleDelete(name)} />
                    </div>
                  </div>
                  <div>{formatFileSize(size)}</div>
                </div>
              </li>
            );
          })}
        </ol>
        {isButtonUploadVisible && (
          <Button
            className={styles.uploadButton}
            onClick={() => filesRef.current?.click()}
          >
            Загрузить файл
          </Button>
        )}
      </div>

      {Boolean(meta.touched && meta.error) && (
        <ErrorMessage className={styles.errorMessage}>
          {meta.error}
        </ErrorMessage>
      )}
    </div>
  );
};
