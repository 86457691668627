import { Form, Formik } from 'formik';
import { BeatLoader } from 'react-spinners';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '../../../base-components/Button.js';
import { FormTextInput } from '../../../base-components/FormTextInput.js';
import { FormFileInput } from '../../../base-components/FormFileInput.js';
import useModalState from '../../_hooks/useModalState.js';
import { Modal } from '../../_shared/index.js';
import styles from './CommercialOffer.module.scss';
import { getHref } from '../../../utils/getHref.js';
import { validationSchema } from './validationSchema.js';

interface Props {
  announceNumber: string;
}

export const CommercialOffer: FC<Props> = ({ announceNumber }) => {
  const [isLoading, setLoading] = useState(true);
  const [isFormModalVisible, showFormModal, hideFormModal] = useModalState();
  const [isSuccessModalVisible, showSuccessModal, hideSuccessModal] =
    useModalState();
  const [successState, setSuccessState] = useState({
    header: 'Ошибка!',
    message: 'Что-то пошло не так. Пожалуйста, попробуйте еще раз',
  });

  const handleSubmit = useCallback(
    async (value: any) => {
      const formData = new FormData();

      formData.append('data[inn]', value.inn);
      formData.append('data[name]', value.name);
      formData.append('data[phone]', value.phone);
      formData.append('data[email]', value.email);
      formData.append('data[announce]', announceNumber);

      for (const file of value.docs) {
        formData.append('docs', file);
      }

      const options = {
        method: 'POST',
        body: formData,
      };

      const url = getHref() + '/rest/v1/public-message/send-commercial-offer';

      try {
        const res = await fetch(url, options);
        const { success } = await res.json();

        if (success) {
          setSuccessState({
            header: 'Спасибо!',
            message: 'Ваше предложение направлено.',
          });
        }
      } catch (error) {
      } finally {
        hideFormModal();
        showSuccessModal();
      }
    },
    [announceNumber, hideFormModal, showSuccessModal],
  );

  const initialValues = useMemo(() => {
    return {
      announce: announceNumber,
      inn: '',
      name: '',
      phone: '',
      email: '',
      docs: [],
    };
  }, [announceNumber]);

  useEffect(() => {
    (async () => {
      try {
        const request = await fetch('/api/organization/getOrg', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const { success, data } = await request.json();

        if (success) {
          initialValues.inn = data.inn;
          initialValues.name = data.name;
          initialValues.phone = data.contact;
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    })();
  }, [initialValues]);

  return (
    <>
      <div onClick={showFormModal}>
        <Button disabled={isLoading} className={styles.button}>
          {isLoading ? <BeatLoader color="#fff" /> : 'Направить предложение'}
        </Button>
      </div>

      {isFormModalVisible && (
        <Modal
          header="Отправка коммерческого предложения"
          onHide={hideFormModal}
        >
          <div className={styles.info}>
            Уважаемый участник! Здесь вы можете прикрепить свое коммерческое
            предложение, которое будет направлено заказчику.
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form className={styles.form}>
                <FormTextInput
                  name="inn"
                  appearance="compact"
                  label="Укажите ваш ИНН компании"
                  className={styles.field}
                />
                <FormTextInput
                  name="name"
                  appearance="compact"
                  label="Укажите наименование компании"
                  className={styles.field}
                />
                <FormTextInput
                  name="phone"
                  appearance="compact"
                  label="Укажите телефон для связи"
                  className={styles.field}
                />
                <FormTextInput
                  name="email"
                  appearance="compact"
                  label="Укажите e-mail"
                  className={styles.field}
                />
                <FormFileInput
                  name="docs"
                  label="Прикрепите файл коммерческого предложения"
                  className={styles.field}
                  single
                />
                <div className={styles.footer}>
                  <Button
                    variant="green"
                    disabled={formik.isSubmitting}
                    type="submit"
                    className={styles.sendBtn}
                  >
                    {formik.isSubmitting ? (
                      <BeatLoader color="#fff" />
                    ) : (
                      'Отправить предложение'
                    )}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      )}

      {isSuccessModalVisible && (
        <Modal onHide={hideSuccessModal} header={successState.header}>
          <p>{successState.message}</p>
        </Modal>
      )}
    </>
  );
};
