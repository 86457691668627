import map from 'lodash/map.js';
import reduce from 'lodash/reduce.js';
import toFinite from 'lodash/toFinite.js';
import { memo } from 'react';
import { FC } from 'react';
import React from 'react';
import { FlatList } from '../../../base-components/FlatList.js';
import CurrencyValue from './CurrencyValue.js';

type Lot = any;

interface Props {
  lots: Lot[];
  isBiddingForUnit: boolean;
  placeholder?: string;
  className?: string;
}

export const AnnounceStartPrice: FC<Props> = memo(
  ({ lots, isBiddingForUnit, placeholder = 'Не определена', className }) => {
    if (!lots || lots.length === 0) {
      return null;
    }
    if (lots.length === 1) {
      const lot = lots[0];
      const lotStartPrice =
        lots[0][isBiddingForUnit ? 'maxContractPrice' : 'startPrice'];
      if (lotStartPrice > 0) {
        return (
          <CurrencyValue
            value={lotStartPrice}
            currencyCode={lot?.startPriceCurrency?.characterCode ?? null}
            className={className}
          />
        );
      }
      return <div className={className}>{placeholder}</div>;
    }
    const startPricesByCurrency = reduce<Lot, Record<string, number>>(
      lots,
      (acc, lot) => {
        const currencyCode = lot?.startPriceCurrency?.characterCode || '';
        const lotStartPrice = isBiddingForUnit
          ? lot.maxContractPrice
          : lot.startPrice;
        acc[currencyCode] = toFinite(acc[currencyCode]) + lotStartPrice;
        return acc;
      },
      {},
    );
    const StartPrices = map(
      startPricesByCurrency,
      (startPrice, currencyCharacterCode) => (
        <CurrencyValue
          key={currencyCharacterCode}
          value={startPrice}
          currencyCode={currencyCharacterCode}
          className={className}
        />
      ),
    );

    return <FlatList list={StartPrices} delimiter=" + " />;
  },
);
