import clsx from 'clsx';
import { ReactNode } from 'react';
import React from 'react';
import styles from './Title.module.scss';

interface TextProps {
  className: string;
  Component?: any;
  children?: ReactNode;
}

const Text: React.FC<TextProps> = ({
  className,
  Component = 'div',
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Component className={clsx(styles.text, className)} {...props} />
);

interface TitleProps {
  title: string;
  /** Выводить полный текст */
  needTooltip?: boolean;
}

const Title: React.FC<TitleProps> = ({ title = '', needTooltip = false }) => {
  return (
    <Text className={styles.text}>
      <span>Наименование:</span>
      <span title={needTooltip ? title : undefined}>
        {title || '[без названия]'}
      </span>
    </Text>
  );
};

export default Title;
