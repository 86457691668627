import clsx from 'clsx';
import moment from 'moment';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Duration from './Duration.js';
import styles from './PhaseTimer.module.scss';
import TimerIcon from './TimerIcon.js';

const zeroDuration = moment.duration();

interface Props {
  finishTs: any;
  showNegative?: any;
}

const PhaseTimer: React.FC<Props> = ({ finishTs, showNegative }) => {
  const [value, setValue] = useState<moment.Duration | null>(null);

  useEffect(() => {
    const timerId = setInterval(() => {
      const nowMoment = moment();

      let duration = moment.duration(finishTs.diff(nowMoment));

      if ((duration.valueOf() as number) < 0 && !showNegative) {
        duration = zeroDuration;
      }

      setValue(duration);
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  });

  const timerWarning = value
    ? value.asSeconds() > 0 && value.asHours() < 1
    : false;

  return (
    <div className={styles.timer}>
      {Boolean(value) && (
        <span className={clsx(timerWarning && styles.warning)}>
          <Duration duration={value} />
        </span>
      )}
    </div>
  );
};

export default PhaseTimer;
