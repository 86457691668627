import filter from 'lodash/filter.js';
import forEach from 'lodash/forEach.js';
import size from 'lodash/size.js';

const notStringNull = (code: string) => code !== 'null';

export default function collectAnnounceRegionCodes(announce: any) {
  const regionCodesSet = new Set<string>();

  if (announce.isSeparateDelivery) {
    forEach(announce.lots, (lot) => {
      if (lot.isSeparateDelivery) {
        if (size(lot.customers)) {
          forEach(lot.customers, (customer) => {
            if (customer.isSeparateDelivery) {
              filter(lot.items, [
                'customerOrgId',
                customer.customerOrgId,
              ]).forEach((item) => {
                regionCodesSet.add(item.deliveryRegionCode);
              });
            } else {
              regionCodesSet.add(customer.deliveryRegionCode);
            }
          });
        } else {
          forEach(lot.items, (item) => {
            regionCodesSet.add(item.deliveryRegionCode);
          });
        }
      } else {
        regionCodesSet.add(lot.deliveryRegionCode);
      }
    });
  } else {
    regionCodesSet.add(announce.deliveryRegionCode);
  }

  return Array.from(regionCodesSet).filter(Boolean).filter(notStringNull);
}
