import React, { useEffect, useState } from 'react';
import formatMomentDate from '../../../utils/formatMomentDate.js';
import toMskMoment from '../../../utils/toMskMoment.js';
import styles from './PublishDate.module.scss';

interface Props {
  publishDate: string;
}

const PublishDate: React.FC<Props> = ({ publishDate }) => {
  const formattedDate = formatMomentDate(
    toMskMoment(publishDate),
    'datetimeLong',
  );

  return (
    <div className={styles.publishDate}>
      {formattedDate ? `Опубликовано ${formattedDate}` : ''}
    </div>
  );
};

export default PublishDate;
