import * as yup from 'yup';
import type { UiTemplateType } from '../types/UiTemplateType.js';
import logger from '../../server/services/logger.js';

type LightPropObj = { value?: unknown } | undefined;

const uiTemplateSchema = yup.object({
  lotStartPrice: yup.object({
    isHidden: yup.boolean().default(false),
    title: yup.string().default('Начальная (максимальная) цена'),
    abbr: yup.string().default('НМЦ'),
    genitiveCaseAbbr: yup.string().default('НМЦ'),
  }),
  publicAnnounceTabCustomer: yup.object({
    isHidden: yup.boolean().default(false),
  }),
  publicAnnounceTabDocs: yup.object({
    isHidden: yup.boolean().default(false),
  }),
  publicAnnounceTabProtocols: yup.object({
    isHidden: yup.boolean().default(false),
  }),
});
const uiTemplateParse = (prop: LightPropObj): UiTemplateType => {
  let value = {};
  if (prop?.value && typeof prop?.value === 'string') {
    try {
      value = JSON.parse(prop?.value) as { [key: string]: string };
    } catch (err) {
      logger.error(err);
    }
  }
  return uiTemplateSchema.validateSync(value);
};

export { uiTemplateParse };
