import { ReactElement } from 'react';
import { FC } from 'react';

interface Props {
  list?: ReactElement[];
  children?: ReactElement[];
  delimiter: string;
}

export const FlatList: FC<Props> = ({ list, delimiter = '', children }) => {
  const items = list || children;
  if (!items) {
    return null;
  }
  return (
    <>
      {items
        .reduce<
          ReactElement[]
        >((prev, curr) => [...prev, <span key={`${curr.props.currencyCode}-${curr.props.value}`}>{delimiter}</span>, curr], [])
        .slice(1)}
    </>
  );
};
