import clsx from 'clsx';
import { ReactNode } from 'react';
import React from 'react';
import styles from './Card.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
}

export const Card: React.FC<Props> = ({ className, children }) => (
  <div className={clsx(styles.card, className)}>{children}</div>
);
