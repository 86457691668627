/**
 * Форматирование числа в виде валюты
 *
 * @param {number} number
 * @param {string} currencyCharacterCode
 * @param {Object} params
 * @param {boolean} params.withoutFraction - Без дробной части
 * @param {boolean} params.onlyNumber - Только число без символа валюты и пробелов разделителей
 * @return {string}
 */

export const formatCurrency = (
  number: number | string,
  currencyCharacterCode: string,
  { withoutFraction = false, onlyNumber = false } = {},
): string => {
  const value = typeof number === 'string' ? parseFloat(number) : number;

  const formatParams: {
    style: 'currency';
    currency: string;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    useGrouping?: boolean;
    currencyDisplay?: string;
  } = {
    style: 'currency',
    currency: currencyCharacterCode || 'RUB',
  };

  if (withoutFraction) {
    formatParams.minimumFractionDigits = 0;
    formatParams.maximumFractionDigits = 0;
  }

  if (onlyNumber) {
    formatParams.useGrouping = false;
    formatParams.currencyDisplay = 'code';
  }

  const formattedValue = new Intl.NumberFormat(
    'ru-RU',
    formatParams as any,
  ).format(value);

  return onlyNumber ? formattedValue.replace(/[^\d,.-]/g, '') : formattedValue;
};
