import React from 'react';
import Badge from './Badge.js';
import styles from './FreeParticipationBadge.module.scss';

type Props = {
  isCurrentOrgFreeParticipation: boolean | number;
};

const FreeParticipationBadge: React.FC<Props> = ({
  isCurrentOrgFreeParticipation,
}) =>
  isCurrentOrgFreeParticipation ? (
    <Badge className={styles.badge}>Бесплатное участие</Badge>
  ) : null;

export default FreeParticipationBadge;
